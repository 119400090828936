import {
  SET_INITIAL_PRODUCTS,
  SHOW_WELCOME_MESSAGE,
  HIDE_WELCOME_MESSAGE,
} from "./actionsTypes";

const initialState = {
  showWelcomMessage: false,
  products: [],
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case SHOW_WELCOME_MESSAGE:
      return {
        ...state,
        showWelcomMessage: action.payload,
      };
    case HIDE_WELCOME_MESSAGE:
      return {
        ...state,
        showWelcomMessage: action.payload,
      };
    default:
      return state;
  }
};

export default shopReducer;
