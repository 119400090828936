import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SET_INITIAL_PRODUCTS } from "../redux/actionsTypes";

function MainNavBar() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/allProducts`)
      .then((res) =>
        dispatch({
          type: SET_INITIAL_PRODUCTS,
          payload: res.data,
        })
      )
      .catch((err) => console.log(err));
  }, []);

  return (
    <nav class="navbar navbar-expand-lg main-header-app">
      <div class="container-fluid">
        <Link class="navbar-brand main-brand-name" to="/">
          GaanvWaale
        </Link>
        <div className="navbar-menu-items">
          <span class="navbar-nav d-flex flex-row navbar-menu-items">
            <Link
              class="nav-link main-nav-links active"
              aria-current="page"
              to="contact"
            >
              Contact
            </Link>
            <Link
              class="nav-link main-nav-links active"
              aria-current="page"
              to="about"
            >
              About
            </Link>
          </span>
        </div>
      </div>
    </nav>
  );
}

export default MainNavBar;
