import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";

function MainCarousel() {
  return (
    <div className="main-carousel-container">
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://images.pexels.com/photos/15023065/pexels-photo-15023065/free-photo-of-close-up-shot-of-masala-chai-tea.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>की Chai</h3>
            <p>Taste the best chai prepared with love from Villagers.</p>
            <Link className="carousel-menu-link" to="/drinks">
              See the menu
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://images.pexels.com/photos/1340116/pexels-photo-1340116.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="Second slide"
          />

          <Carousel.Caption>
            <h3>के Masale</h3>
            <p>Tasty and healthy spices for your daily needs.</p>
            <Link className="carousel-menu-link" to="/spices">
              See the menu
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://images.unsplash.com/photo-1571091718767-18b5b1457add?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8YnVyZ2VyfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>का Burger</h3>
            <p>Ever tasted burger from a village? Try this one!</p>
            <Link className="carousel-menu-link" to="/burgers">
              See the menu
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default MainCarousel;
