import React from "react";
import { useSelector } from "react-redux";
import ProductItem from "../shared/ProductItem";

function Chai() {
  const state = useSelector((state) => state);
  const filteredItems =
    state.products && state.products.filter((i) => i.category === "drinks");
  return (
    <div className="main-drinks-container">
      {filteredItems.map((i) => (
        <ProductItem
          name={i.name}
          price={i.price}
          currency={i.currency}
          description={i.description}
          subcategory={i.subcategory}
        />
      ))}
    </div>
  );
}

export default Chai;
