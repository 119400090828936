import React from "react";

function Contact() {
  const contactMethods = [
    {
      name: "phone",
      title: "Phone",
      contactDetail: ["+91-9407190731", "+91-9669333088"],
    },
    {
      name: "email",
      title: "Email",
      contactDetail: [
        "iliyasmansuri731@gmail.com",
        "mansurinadim221@gmail.com",
      ],
    },
    {
      name: "whatsapp",
      title: "Whatsapp",
      contactDetail: ["+91-9407190731", "+91-9407109736"],
    },
  ];
  return (
    <div className="main-contact-page">
      <div className="contact-methods">
        {contactMethods.map((i) => (
          <div class="card" key={i.name}>
            <h5>{i.title}</h5>
            {i.contactDetail.map((j) => (
              <h6>{j}</h6>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Contact;
