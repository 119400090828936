import "./App.css";
import { Provider } from "react-redux";
import store from "./components/redux/store";
import { Route, Routes } from "react-router-dom";
import MainNavBar from "./components/shared/MainNavBar";
import HomePage from "./components/container/HomePage";
import Contact from "./components/container/Contact";
import About from "./components/container/About";
import Chai from "./components/container/Chai";
import Spice from "./components/container/Spice";
import Burger from "./components/container/Burger";

const App = () => {
  return (
    <Provider store={store}>
      <MainNavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/drinks" element={<Chai />} />
        <Route path="/spices" element={<Spice />} />
        <Route path="/burgers" element={<Burger />} />
      </Routes>
    </Provider>
  );
};

export default App;
