import MainCarousel from "../shared/MainCarousel";

const HomePage = () => {
  return (
    <div>
      <MainCarousel />
    </div>
  );
};

export default HomePage;
