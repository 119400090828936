import React from "react";

function ProductItem({ name, price, currency, description }) {
  return (
    <div className="main-product-container">
      <div className="product-name">{name}</div>
      <div className="product-price">{`${price} ${currency}`}</div>
      <div className="product-description">{description}</div>
    </div>
  );
}

export default ProductItem;
